import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/javascript/service-workers",
  "date": "2015-11-23",
  "title": "SERVICE WORKERS",
  "author": "admin",
  "tags": ["development", "javascript", "service-worker"],
  "featuredImage": "feature.jpg",
  "excerpt": "Service Worker is a new browser feature which provides event-driven scripts that run independently of web pages. Unlike other workers, Service Workers can be shut down at the end of events, note the lack of retained references from documents, and they have access to domain-wide events such as network fetches."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type="info" title="Going Offline" content="Service Workers have `scriptable caches`. Along with the ability to respond to network requests from certain web pages via a script, this provides a way for applications to “go offline”." mdxType="Message" />
    <p>{`Service Worker is an API that lives inside the browser and sits between your web pages and your application servers. Once installed and activated, a ServiceWorker can programmatically determine how to respond to requests for resources from your origin, even when the browser is offline.`}</p>
    <Message type="warn" title="HTML5 Application Cache" content="Service Workers are meant to replace the HTML5 Application Cache (AppCache). Unlike AppCache, Service Workers are comprised of scriptable primitives that make it possible for application developers to build URL-friendly, always-available applications in a sane and layered way." mdxType="Message" />
    <h2>{`Browser Support`}</h2>
    <p>{`Browser support for service wworkers is still behind. Google Chrome, Opera, and Firefox support service workers behind a `}<strong parentName="p">{`configuration flag`}</strong>{`.`}</p>
    <h3>{`Service workers versus Shared Workers`}</h3>
    <p>{`Similarities:`}</p>
    <ul>
      <li parentName="ul">{`No DOM access`}</li>
      <li parentName="ul">{`Not tied to a page`}</li>
      <li parentName="ul">{`Runs in its own global script context (own thread)`}</li>
    </ul>
    <p>{`Differences`}</p>
    <ul>
      <li parentName="ul">{`HTTPS only`}</li>
      <li parentName="ul">{`Defined upgrade model`}</li>
      <li parentName="ul">{`Runs without any page at all`}</li>
      <li parentName="ul">{`Even-driven, so can terminate when not in use, and run again when used`}</li>
    </ul>
    <h2>{`Over HTTPS/Secure Connections only`}</h2>
    <p>{`Using service worker you can hijack connections, respond differently & filter responses. While you would use these powers for good, a man-in-the-middle might not. To avoid this, you can only register for service workers on pages served over HTTPS, so we know the service worker the browser receives hasn't been tampered with during its journey through the network.`}</p>
    <h2>{`A Promise Based API`}</h2>
    <p>{`The future of web browser API implementations is Promise-heavy. The `}<strong parentName="p">{`fetch API`}</strong>{`, for example, sprinkles sweet Promise-based sugar on top of XMLHttpRequest. ServiceWorker makes occasional use of fetch, but there’s also worker registration, caching, and message passing, all of which are Promise-based.`}</p>
    <h2>{`Implement a Service Worker`}</h2>
    <p>{`Let's start by creating a basic app, and add offline support to it.`}</p>
    <h3>{`Registering a Service Worker`}</h3>
    <p>{`The first step is registering the service worker. In a `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` file, add following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if ('serviceWorker' in navigator) {

}
`}</code></pre>
    <p>{`Here we are detecting if the feature is available.`}</p>
    <p>{`Next, we will call `}<inlineCode parentName="p">{`.register`}</inlineCode>{` and pass in a JavaScript resource to be executed in the context of a service worker.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if ('serviceWorker' in navigator) {
  console.log('CLIENT: registration in progress.');
  navigator.serviceWorker.register('service-worker.js').then(function() {
    console.log('CLIENT: registration complete.');
  }, function(err) {
    console.log('CLIENT: registration failure - ' + err);
  });
}
`}</code></pre>
    <Message type="warn" title="" content="Make sure your `service-worker.js` file is served from the `root` directory, not from `src` or `js` or similar directory, because the **context** of your service worker will be limited to the given folder your JS file is in." mdxType="Message" />
    <p>{`To test this, you can fire up a server using `}<strong parentName="p">{`SimpleHTTPServer`}</strong>{` as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`  python -m  SimpleHTTPServer 8901
`}</code></pre>
    <h2>{`Lifecycle`}</h2>
    <p>{`Service worker script goes through three stages when you call `}<inlineCode parentName="p">{`register`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`fetch`}</inlineCode>{` - this event fires whenever a request originates from your service worker scope`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`install`}</inlineCode>{` - this event fires when a service worker is first fetched`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`activate`}</inlineCode>{` - this event fires after a successful installation`}</li>
    </ul>
    <h2>{`Installing the service worker`}</h2>
    <p>{`A version number is useful when updating the worker logic, allowing you to remove outdated cache entries during the activation step.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var version = 'v1::';
`}</code></pre>
    <p>{`You can use events to interact with `}<inlineCode parentName="p">{`install`}</inlineCode>{` and `}<inlineCode parentName="p">{`activate`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var version = 'v1::';

self.addEventListener('install', function(event) {
  console.log('WORKER: install event in progress');
  event.waitUntil(
    caches.open(version).then(function(cache) {
      return cache.addAll([
          '/',
          '/css/style.css',
          '/src/index.js'
        ]);
    }).then(function() {
      console.log('WORKER: install completed');
    })
  );
});
`}</code></pre>
    <p>{`You can pass a promise to `}<inlineCode parentName="p">{`event.waitUntil`}</inlineCode>{` to extend the installation process. Once `}<inlineCode parentName="p">{`activate`}</inlineCode>{` event fires, your service worker can control pages.`}</p>
    <h2>{`Intercept Fetch Requests`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` event fires whenever a page controlled by this service worker requests a resource. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`self.addEventListener("fetch", function(event) {
  console.log('WORKER: fetch event in progress.');

  if (event.request.method !== 'GET') {
    console.log(
      'WORKER: fetch event ignored.',
      event.request.method, event.request.url
    );
    return;
  }

  event.respondWith(
    caches.match(event.request).then(function(cached) {
      var networked = fetch(event.request)
        .then(fetchedFromNetwork, unableToResolve)
        .catch(unableToResolve);

      console.log(
        'WORKER: fetch event', cached ? '(cached)' :
        '(network)', event.request.url
      );

      return cached || networked;

      function fetchedFromNetwork(response) {
        var cacheCopy = response.clone();

        console.log(
          'WORKER: fetch response from network.',
          event.request.url
        );
        caches
          .open(version + 'pages')
          .then(function add(cache) {
            cache.put(event.request, cacheCopy);
          })
          .then(function() {
            console.log(
              'WORKER: fetch response stored in cache.',
              event.request.url
            );
          });

          return response;
      }

      function unableToResolve () {
        console.log(
          'WORKER: fetch request failed in both cache and network.'
        );

        return new Response('<h1>Service Unavailable</h1>', {
          status: 503,
          statusText: 'Service Unavailable',
          headers: new Headers({
            'Content-Type': 'text/html'
          })
        });
    });
});
`}</code></pre>
    <p>{`Let's have a look at what's going on here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`caches.keys()
`}</code></pre>
    <p>{`This method returns a promise which will resolve to an array of available cache keys.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`return Promise.all( ...
`}</code></pre>
    <p>{`We return a promise that settles when all outdated caches are deleted.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`.filter(function (key) {
  return !key.startsWith(version);
})
`}</code></pre>
    <p>{`Filter by keys that don't start with the latest version prefix.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`.map(function (key) {
  return caches.delete(key);
})
`}</code></pre>
    <p>{`Return a promise that's fulfilled when each outdated cache is deleted.`}</p>
    <p>{`The code snippets for this post can be found on `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/service-workers"
      }}>{`Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      